import React from "react";

import "./tfss.scss";


function Tfss001(){


    return(
        <div className="cert">
            <img src={require("../images/tfss001.png")} alt="" />
            
        </div>
    )
}

export default Tfss001;