import React from 'react';
import "./Independence.scss";
import thefaujilogo from "../images/TheFaujiLogo.png";
import FirstWar from "../images/03-1857-rebellion.jpg"
import Formation from "../images/1st_INC1885.jpg"
import NonCooperation from "../images/Noncooperation_movement1922.jpg";
import Civil from "../images/freedom-struggle-16768209373x2.webp";
import Quit from "../images/gandhi_1439113557.jpg"
import Partition from "../images/partition_1565689980.jpg"
import Independence from "../images/PM_Nehru_addresses.jpg"
import Download1 from "../images/download1.jpg"
import Download2 from "../images/download2.jpg"
import DANDI from "../images/DANDI.jpg"
import Download4 from "../images/download4.jpg"
import Images5 from "../images/images5.jpg";





function Independece() {


  return (
    <div className='independence-body'>
            <img className="logo" src={thefaujilogo} alt="logo" />
      <div>
        <h2 className='independence-title'>History of India's Independence</h2>
      </div>
      <div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src={FirstWar} alt="First War of Indian Independence" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h5>First War of Indian Independence (1857):</h5> 
              <ul>
                <li>
                  <p>
                    The First War of Indian Independence was an uprising against British colonial rule in India, caused by economic 
                    exploitation, religious and cultural tensions, and a desire for political freedom.
                  </p>
                </li>
                <li>
                  <p>
                  It began in May 1857 when Indian soldiers known as sepoys revolted in Meerut and quickly spread across India, 
                  involving various groups for about two years.
                  </p>
                </li>
                <li>
                  <p>
                  The rebellion exposed the exploitative nature of British colonial rule and fueled the Indian freedom struggle.
                  </p>
                </li>
                <li>
                  <p>
                  Its impact on India's struggle for independence was significant, leading to the end of British East India Company's 
                  rule and India's independence in 1947.
                  </p>
                </li>
              </ul>
            </p>
          </div>

        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src={Formation} alt="Formation of Indian National Congress" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h5>Formation of Indian National Congress (1885):</h5>
              <ul>
                <li>
                <p>
                The Indian National Congress was formed in December 1885 as a political party to represent Indian interests and promote 
                national unity.
                </p>
                </li>
                <li>
                  <p>
                  It was formed by a group of educated, middle-class Indians, including Allan Octavian Hume, Dadabhai Naoroji, and Dinshaw Wacha.
                  </p>
                </li>
                <li>
                <p>
                The Congress initially focused on seeking greater political representation for Indians in British colonial government and 
                advocating for social and economic reforms.
                </p>
                </li>
                <li>
                <p>The Congress played a significant role in India's struggle for independence and ultimately became the dominant political 
                party in post-independence India.</p>
                </li>
              </ul>
            </p>
          </div>


        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src={NonCooperation} alt="Non-Cooperation Movement" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h5>Non-Cooperation Movement (1920-1922):</h5> 
              <ul>
                <li>
                <p>
                The Non-Cooperation Movement was a mass civil disobedience campaign against British rule in India led by Gandhi and the Indian 
                National Congress in 1920.
                </p>
                </li>
                <li>
                <p>
                It aimed to unite Indians across religious, ethnic, and class lines through nonviolent means, including boycotts of British 
                goods, institutions, and the civil service.
                </p>
                </li>
                <li>
                <p>
                The movement gained significant momentum, but was called off in 1922 after a violent clash between protesters and police in 
                Chauri Chaura, Uttar Pradesh.
                </p>
                </li>
                <li>
                <p>
                The Non-Cooperation Movement marked a turning point in India's struggle for independence and inspired subsequent movements of 
                nonviolent resistance worldwide.
                </p>
                </li>
              </ul>
            </p>
          </div>

        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src={Civil} alt="" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h5>Civil Disobedience Movement (1930-1934):</h5> 
              <ul>
                <li>
                  <p>
                  The Civil Disobedience Movement was a mass nonviolent campaign against British colonial rule in India led by Gandhi and the Indian 
                  National Congress in 1930.
                  </p>
                </li>
                <li>
                  <p>
                  It aimed to challenge British authority through civil disobedience, including boycotting British goods, refusing to pay taxes, and 
                  participating in mass protests.
                  </p>
                </li>
                <li>
                  <p>
                  The movement gained widespread support across India, with thousands of people being arrested and imprisoned.                  </p>
                </li>
                <li>
                  <p>
                  The movement inspired subsequent mass movements of nonviolent resistance and contributed to India's eventual independence in 1947.
                  </p>
                </li>
              </ul>
              </p>
          </div>


        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src={Quit} alt="" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h5>Quit India Movement (1942):</h5> 
              <ul>
                <li>
                  <p>
                  The Quit India Movement was a mass civil disobedience campaign launched by the Indian National Congress under the leadership of 
                  Mahatma Gandhi in August 1942.
                  </p>
                </li>
                <li>
                  <p>
                  The movement aimed to force the British government to "quit India" and transfer power to the Indian people by engaging in acts of 
                  nonviolent civil disobedience, including strikes, protests, and acts of sabotage.
                  </p>
                </li>
                <li>
                  <p>
                  The movement resulted in widespread protests, strikes, and acts of sabotage, as well as many arrests and brutal crackdowns by the 
                  British colonial government.
                  </p>
                </li>
                <li>
                  <p>
                  Despite being suppressed by the British, the Quit India Movement played a significant role in India's eventual independence in 1947.
                  </p>
                </li>
              </ul>
            </p>
          </div>

        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src={Partition} alt="" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h5>Partition of India (1947):</h5> 
              <ul>
                <li>
                  <p>
                  The Partition of India was the division of British India into two independent dominions, India and Pakistan, on August 14-15, 1947.
                  </p>
                </li>
                <li>
                  <p>
                  It was a result of religious and political tensions between Hindus and Muslims, and the failure of efforts to establish a unified, independent India.
                  </p>
                </li>
                <li>
                  <p>
                  The partition resulted in mass displacement and migration, leading to widespread violence and communal riots.
                  </p>
                </li>
                <li>
                  <p>
                  The partition marked a significant turning point in South Asian history, with long-lasting effects on the region's politics, society, 
                  and relations between India and Pakistan.
                  </p>
                </li>
              </ul>
            </p>
          </div>


        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src={Independence} alt="" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h5>Independence and Formation of Republic of India (1947-1950):</h5> 
              <ul>
                <li>
                  <p>
                  India gained independence from British colonial rule on August 15, 1947, after a long struggle for self-rule led by the Indian National 
                  Congress and other nationalist leaders.
                  </p>
                </li>
                <li>
                  <p>
                  The country's independence was marked by the partition of India into two dominions, India and Pakistan, resulting in widespread violence 
                  and mass displacement.
                  </p>
                </li>
                <li>
                  <p>
                  India adopted its constitution on January 26, 1950, officially becoming a republic and establishing a parliamentary democracy.
                  </p>
                </li>
                <li>
                  <p>
                  The formation of the Republic of India marked a significant milestone in the country's history, setting the stage for its emergence as a 
                  major political and economic power in the postcolonial world.
                  </p>
                </li>
              </ul>
            </p>
          </div>

        </div>
      </div>
      <hr />
      <div>
        <h2>Salt Satyagraha</h2>
      </div>
      <div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src={Download1} alt="" />

          </div>
          <div className='col-lg-8'>
          <p className='event-para'>
          <h5>The Beginning of the Campaign</h5>
            <ul>
              <li>
                <p>
                In March 1930, Gandhi and the Indian National Congress launched the Salt Satyagraha to challenge British salt laws.

                </p>
              </li>
              <li>
                <p>
                They began a 24-day march from Ahmedabad to Dandi in Gujarat, sparking enthusiasm and support for the campaign.                </p>
              </li>
              <li>
                <p>
                The campaign aimed to defy British salt laws, which prohibited Indians from producing or selling salt without paying a heavy tax.

                </p>
              </li>
              <li>
                <p>
                It symbolized India's fight for independence and its determination to challenge British colonial rule.
                </p>
              </li>
            </ul>
          </p>
            
          </div>


        </div>

        <div className='events row'>
          <div className='col-lg-4 rounded-border-gradient'>
            <img className='image ' src={Download2} alt="" />

          </div>
          <div className='col-lg-8'>
          <p className='event-para'>
          <h5>The Production of Salt at Dandi</h5>
            <ul>
              <li>
                <p>
                On April 6, 1930, Gandhi and his followers reached the coastal town of Dandi and began producing salt from seawater.
                </p>
              </li>
              <li>
                <p>
                The act of producing salt was symbolic of India's fight for independence and its determination to challenge British colonial rule.
                </p>
              </li>
              <li>
                <p>
                The British authorities responded swiftly by arresting Gandhi and other leaders of the campaign, but the production of salt at Dandi had already sparked acts of civil disobedience and protest across India.                </p>
              </li>
              <li>
                <p>
                The production of salt at Dandi sparked similar acts of civil disobedience and protest across India, leading to the arrest of tens of thousands of people.
                </p>
              </li>
            </ul>
          </p>
          
          </div>

        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src={DANDI} alt="" />

          </div>
          <div className='col-lg-8'>
          <p className='event-para'>
          <h5>The Widespread Protests and Repression</h5>
            <ul>
              <li>
                <p>
                The Salt Satyagraha triggered widespread protests and demonstrations across India, with people organizing marches, boycotts, and acts of civil disobedience.
                 </p>
              </li>
              <li>
                <p>
                The British authorities responded with brutal repression, arresting tens of thousands of people and using force to suppress protests.
                </p>
              </li>
              <li>
                <p>
                The campaign contributed to the growing momentum of India's struggle for independence, leading to the eventual end of British colonial rule in 1947.
                </p>
              </li>
              <li>
                <p>
                It inspired similar movements of civil disobedience and nonviolent resistance around the world.
                </p>
              </li>
            </ul>
          </p>
          
          </div>


        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src={Download4} alt="" />

          </div>
          <div className='col-lg-8'>
          <p className='event-para'>
          <h5>The Significance of the Salt Satyagraha</h5>
            <ul>
              <li>
                <p>
                The Salt Satyagraha was a landmark moment in India's struggle for independence.
                </p>
              </li>
              <li>
                <p>
                It brought international attention to India's struggle for independence and inspired subsequent generations of activists and civil rights leaders.
                </p>
              </li>
              <li>
                <p>
                It marked a turning point in the Indian nationalist movement, galvanizing public support and contributing to the emergence of new leaders and organizations.
                </p>
              </li>
              <li>
                <p>
                It remains an iconic moment in India's history, inspiring people to use nonviolent resistance as a means of achieving political and social change.
                </p>
              </li>
            </ul>
          </p>
          
          </div>


        </div>
      </div>
    </div>
  )
}

export default Independece;
