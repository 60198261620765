import React from 'react';
import './App.scss';
import '../Header/Header.jsx';
import Header from '../Header/Header.jsx';
import QR from '../QrPage/QR.jsx';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Home/Home";
import Footer from '../Footer/Footer';
import Contact from '../Contact/Contact';
import Constitution from '../Constitution/Constitution';
import Independence from '../Independence/Independence';
import Proud from "../Proud/Proud";
import Defense from '../Defense/Defense';

// hidden certificates for verification
import Tfss001 from '../HiddenCert/Tfss001';
import Tfss002 from '../HiddenCert/Tfss002';
import Tfss003 from '../HiddenCert/Tfss003';

function App() {

  
  return (
    <div className='app-component'>
      <Header />
      
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/the-constitution" element={<Constitution />} />
          <Route path="/the-independence" element={<Independence />} />
          <Route path="/the-proud" element={<Proud />} />
          <Route path="/the-armed-forces" element={<Defense />} />

          <Route path="/the-patriotism" element={<QR />} />
          <Route path="/contact-us" element={<Contact />} />

          {/* hidden certificates */}
          <Route path="/careers/certificate/tfss001" element={<Tfss001 />} />
          <Route path="/careers/certificate/tfss002" element={<Tfss002 />} />
          <Route path="/careers/certificate/tfss003" element={<Tfss003 />}/>
          
          

        </Routes>
      
      <Footer />
    </div>
  )
}

export default App;
