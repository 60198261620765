import React from 'react';
import "./Defense.scss";
import thefaujilogo from "../images/TheFaujiLogo.png";


function Defense() {


    return (
        <div className='proud-body'>
            <img className="logo" src={thefaujilogo} alt="logo" />

            <div>
                <h2 className='proud-title'>Proud Facts About Indian Armed Forces</h2>
            </div>
            <div>

                <div className='events row'>

                    <div className='col-lg-4'>
                        <img className='image' src="https://images.pexels.com/photos/13316041/pexels-photo-13316041.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                    </div>
                    <div className='col-lg-8'>
                        <p className='event-para'>
                            <h4>Indian Army</h4>
                            <ul>
                                <li>

                                    <p><h5>World's 2nd largest army: </h5>With over 1.4 million active troops and another million reserve troops, the Indian Army is the second largest army in the world.
                                    </p>
                                </li>
                                <li>

                                    <p>

                                        <h5>Modern capabilities: </h5>Today, the Indian Army is a modern, well-equipped force with advanced weaponry and technology, including tanks, aircraft, and drones.

                                    </p>
                                </li>
                                <li>

                                    <p>


                                        <h5>War history: </h5>The Indian Army has a proud history of defending the nation in times of war, including major conflicts such as the 1965 war with Pakistan and the 1971 war that led to the liberation of Bangladesh.

                                    </p>
                                </li>
                                <li>

                                    <p>
                                        <h5>Surgical Strike: </h5>

                                        In 2016, Indian Army conducted a surgical strike against terrorist bases in Pakistan-occupied Kashmir. The operation was a success and sent a strong message to the terrorists and their sponsors.                </p>
                                </li>
                                <li>

                                    <p>

                                        <h5>Courage and sacrifice: </h5>Indian soldiers are known for their bravery and sacrifice, with many soldiers having received the highest awards for gallantry, such as the Param Vir Chakra and the Ashoka Chakra.

                                    </p>
                                </li>
                            </ul>
                        </p>

                    </div>
                </div>


                <div className='events row'>
                    <div className='col-lg-4'>
                        <img className='image' src="https://images.pexels.com/photos/14084411/pexels-photo-14084411.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />

                    </div>
                    <div className='col-lg-8'>
                        <p className='event-para'>
                            <h4>Indian Air Force</h4>
                            <ul>
                                <li>
                                    <p>
                                        <h5>Fourth largest air force: </h5>The Indian Air Force (IAF) is the fourth largest air force in the world, with over 140,000 personnel and over 1,500 aircraft.

                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <h5>Aerial history: </h5>The IAF has a rich history dating back to its formation in 1932, with many notable aerial battles fought during major conflicts such as the World War II and the Indo-Pakistani wars.

                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <h5>Technologically advanced: </h5>Today, the IAF is a modern, technologically advanced force with a wide range of fighter jets, transport planes, and helicopters.

                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <h5>Strategic role: </h5>The IAF plays a critical role in safeguarding India's airspace and protecting the nation's security, with a presence along the borders with Pakistan and China.

                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <h5>Humanitarian missions: </h5>The IAF has played a crucial role in several humanitarian and disaster relief missions, including during the 2015 Nepal earthquake and the recent COVID-19 pandemic.


                                    </p>
                                </li>
                            </ul>
                        </p>

                    </div>


                </div>

                <div className='events row'>
                    <div className='col-lg-4'>
                        <img className='image' src="https://live.staticflickr.com/3433/3792930855_b942e24582_m.jpg" alt="" />

                    </div>
                    <div className='col-lg-8'>
                        <p className='event-para'>
                            <h4>Indian Navy</h4>
                            <ul>
                                <li>
                                    <p>
                                        <h5>One of the world's largest navies: </h5>The Indian Navy is one of the world's largest navies, with over 67,000 personnel, including sailors and officers.


                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <h5>Naval history: </h5>The Indian Navy has a rich history dating back over 400 years, with its origins in the naval forces of the East India Company.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <h5>Modern capabilities: </h5>Today, the Indian Navy is a modern, technologically advanced force with a wide range of ships, submarines, and aircraft.

                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <h5>Strategic location: </h5>The Indian Navy plays a critical role in safeguarding India's maritime interests, with a presence in the Indian Ocean Region, the Persian Gulf, and the South China Sea.


                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <h5>Humanitarian missions: </h5>The Indian Navy has participated in several humanitarian and disaster relief missions, including during the 2004 Indian Ocean tsunami and the recent COVID-19 pandemic.

                                    </p>
                                </li>
                            </ul>
                        </p>

                    </div>


                </div>


            </div>

        </div>
    )
}

export default Defense;
