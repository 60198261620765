import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Contact.scss";

function Contact(props) {
    const [fullName, setFullName] = useState([]);
    const [email, setEmail] = useState([]);
    const [phone, setPhone] = useState([]);
    const [message, setMessage] = useState([]);

    const url = "https://thefauji-backend-juqu.onrender.com/";


    useEffect(() => {

        const getData = async () => {

            //get notes from mongodb
            const { data } = await axios.get(url);
            console.log(data);
        }
        getData();
    }, [])

    function handleChange(event) {
        const { value, name } = event.target;
        if (name === "name") {
            setFullName(value);
            console.log(fullName);
        }
        if (name === "email") {
            setEmail(value);
            console.log(email);
        }
        if (name === "phone") {
            setPhone(value);
            console.log(phone);
        }
        if (name === "message") {
            setMessage(value);
            console.log(message);
        }
    }


    const handleSubmit = async (event) => {

        event.preventDefault();

        const details = {
            name: fullName,
            email: email,
            phone: phone,
            message: message
        };

        console.log(details);
        await axios.post(url + "api/data", { details });
    }


    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label for="exampleInputEmail1" className="form-label">Name</label>
                    <input onChange={handleChange} name="name" value={fullName} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                    <label for="exampleInputPassword1" className="form-label">Email</label>
                    <input onChange={handleChange} name="email" value={email} type="email" className="form-control" id="exampleInputPassword1" />
                </div>
                <div className="mb-3">
                    <label for="exampleInputPassword1" className="form-label">Phone</label>
                    <input onChange={handleChange} name="phone" value={phone} type="number" className="form-control" id="exampleInputPassword2" />
                </div>
                <div className="mb-3">
                    <label for="message" className="form-label">Message</label>
                    <textarea onChange={handleChange} name="message" value={message} type="text" rows="4" className="form-control" id="message" />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
}

export default Contact;
