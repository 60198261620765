import React from 'react';
import "./Proud.scss";
import thefaujilogo from "../images/TheFaujiLogo.png";


function Proud() {


  return (
    <div className='proud-body'>
      <img className="logo" src={thefaujilogo} alt="logo" />

      <div>
        <h2 className='proud-title'>Proud Facts About India</h2>
      </div>
      <div>


        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src="https://live.staticflickr.com/34/67394736_37a12a3ed5_n.jpg" alt="" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h4>Indian Culture</h4>
              <ul>
                <li>
                  <p>
                    India is home to one of the world's oldest civilizations, the Indus Valley Civilization, which flourished over 5,000 years ago.

                  </p>
                </li>
                <li>
                  <p>
                    India has a rich tradition of literature, with ancient texts like the Vedas, the Mahabharata, and the Ramayana, as well as more recent works by writers like Rabindranath Tagore and Vikram Seth.

                  </p>
                </li>
                <li>
                  <p>
                    Indian classical music and dance are world-renowned, with various styles and forms that have been developed over centuries.

                  </p>
                </li>
                <li>
                  <p>
                    Indian cuisine is diverse and flavorful, with regional specialties that range from spicy curries to sweet desserts.


                  </p>
                </li>
                <li>
                  <p>
                    India is known for its traditional handicrafts, including intricate embroidery, pottery, and weaving.
                  </p>
                </li>
              </ul>
            </p>

          </div>


        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src="https://live.staticflickr.com/65535/51472995119_8609935796_m.jpg" alt="" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h4> Indian Sports And Its Achievements</h4>
              <ul>
                <li>
                  <p>
                    India has won 28 medals in the Olympic Games, including 9 gold medals.


                  </p>
                </li>
                <li>
                  <p>
                    India has won 101 medals in the Commonwealth Games, including 28 gold medals.

                  </p>
                </li>
                <li>
                  <p>
                    India has won 26 medals in the Asian Games, including 9 gold medals.

                  </p>
                </li>
                <li>
                  <p>
                  India has a strong tradition in field hockey, having won 8 Olympic gold medals in the sport.


                  </p>
                </li>
                <li>
                  <p>
                    India has won the Davis Cup tennis tournament twice.


                  </p>
                </li>
                <li>
                  <p>
                    India has produced many world-class athletes, including Milkha Singh, PT Usha, Mary Kom, Abhinav Bindra, Saina Nehwal, PV Sindhu, and many others.



                  </p>
                </li>
                <li>
                  <p>


                  India is the only country to have won the cricket World Cup on home soil.


                  </p>
                </li>
              </ul>
            </p>

          </div>


        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src="https://live.staticflickr.com/1648/26231586541_e476f333b7_n.jpg" alt="" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h4>Indian Food And Cuisine</h4>
              <ul>
                <li>
                  <p>
                  Indian cuisine is characterized by its use of aromatic spices and herbs, which give it a unique and rich flavor profile.



                  </p>
                </li>
                <li>
                  <p>
                  India has a long tradition of vegetarianism, with many delicious vegetarian dishes like paneer tikka, chana masala, and dosa.


                  </p>
                </li>
                <li>
                  <p>
                  Indian street food is world-renowned, with popular dishes like chaat, vada pav, and samosas.


                  </p>
                </li>
                <li>
                  <p>
                  Indian cuisine has influenced many other cuisines around the world, including British, Caribbean, and South African cuisine.

</p>
                </li>
                <li>
                  <p>
                  India is the world's largest producer and consumer of spices, with more than 70 different spices grown in the country.


</p>
                </li>
              </ul>
            </p>

          </div>
        </div>


        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src="https://live.staticflickr.com/1922/43816153510_69fcf50bfd_m.jpg" alt="" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h4>Indian Geography</h4>
              <ul>
                <li>
                  <p>
                  India is the seventh-largest country in the world by land area, with an area of over 3.2 million square kilometers.




                  </p>
                </li>
                <li>
                  <p>
                  The Indian Ocean surrounds India to the south, the Bay of Bengal to the east, and the Arabian Sea to the west.



                  </p>
                </li>
                <li>
                  <p>
                  The Himalayan mountain range, the world's highest mountain range, lies along India's northern border, with peaks that include Mount Everest, K2, and Kangchenjunga.



                  </p>
                </li>
                <li>
                  <p>
                  The Thar Desert, also known as the Great Indian Desert, covers a large part of western India and is one of the world's largest deserts.


                  </p>
                </li>
                <li>
                  <p>
                  The Ganges, one of the world's most sacred rivers, flows through northern India, providing water to millions of people and supporting a rich ecosystem.



                  </p>
                </li>
              </ul>
            </p>

          </div>
        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src="https://live.staticflickr.com/3174/2508241466_2bdd87ff64_n.jpg" alt="" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h4>Indian Diplomacy</h4>
              <ul>
                <li>
                  <p>
                  India is one of the founding members of the Non-Aligned Movement, which seeks to promote global peace and cooperation while maintaining neutrality in international affairs.





                  </p>
                </li>
                <li>
                  <p>
                  India is a founding member of the United Nations and has contributed significantly to peacekeeping operations around the world.




                  </p>
                </li>
                <li>
                  <p>
                  India has a long history of peace and nonviolence, with leaders like Mahatma Gandhi and Jawaharlal Nehru promoting peaceful diplomacy as a way to resolve conflicts.




                  </p>
                </li>
                <li>
                  <p>
                  India has maintained friendly relations with its neighbors, including Pakistan, Bangladesh, and Nepal, and has worked to resolve conflicts through dialogue and negotiation.


                  </p>
                </li>
              </ul>
            </p>

          </div>
        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src="https://live.staticflickr.com/4764/26002182438_eb31dbbd6c_n.jpg" alt="" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h4>Indian People Abroad</h4>
              <ul>
                <li>
                  <p>
                  Indian-Americans like Google CEO Sundar Pichai and former PepsiCo CEO Indra Nooyi have made significant contributions to the technology and business sectors in the United States.





                  </p>
                </li>
                <li>
                  <p>
                  Indian-origin people in the UK, such as Chancellor of the Exchequer Rishi Sunak and novelist Salman Rushdie, have excelled in fields like politics and literature.




                  </p>
                </li>
                <li>
                  <p>
                  Indian-origin entrepreneurs in Silicon Valley, like Vinod Khosla and Shantanu Narayen, have founded successful tech companies.




                  </p>
                </li>
                <li>
                  <p>
                  Indian doctors and medical researchers, like Surgeon General Vivek Murthy, have made important contributions to the field of medicine around the world.

                  </p>
                </li>
                <li>
                  <p>
                  Indian artists and cultural figures, like musician Ravi Shankar and filmmaker Mira Nair, have made significant contributions to the arts and culture in various countries.


                  </p>
                </li>
              </ul>
            </p>

          </div>
        </div>

        {/* <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src="https://live.staticflickr.com/5548/12313926054_f7cf8d0f44_m.jpg" alt="" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h4>Satya Nadella</h4>
              <ul>
                <li>
                  <p>
                    <h5>CEO of Microsoft: </h5>Satya Nadella is the CEO of Microsoft, one of the world's largest and most influential technology companies.

                  </p>
                </li>
                <li>
                  <p>
                    <h5>Leadership: </h5>As the CEO of Microsoft, Satya Nadella has been instrumental in driving the company's growth and innovation, and has championed the development of new products and services.

                  </p>
                </li>
                <li>
                  <p>
                    <h5>Visionary: </h5>Satya Nadella is widely regarded as a visionary leader, who has focused on the power of technology to transform industries and societies.

                  </p>
                </li>
                <li>
                  <p>
                    <h5>Honours and awards: </h5>Satya Nadella has received several honours and awards for his contributions to business and technology, including being named one of Time's 100 most influential people.

                  </p>
                </li>
              </ul>
            </p>

          </div>
        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src="https://live.staticflickr.com/5093/5468766668_9e74f0956d_m.jpg" alt="" />

          </div>
          <div className='col-lg-8'>
            <p className='event-para'>
              <h4>Shantanu Narayen</h4>
              <ul>
                <li>
                  <p>
                    <h5>CEO of Adobe: </h5>Shantanu Narayen is the CEO of Adobe, a leading software company that produces popular products such as Photoshop, Acrobat, and Creative Cloud.


                  </p>
                </li>
                <li>
                  <p>
                    <h5>Leadership: </h5>As the CEO of Adobe, Shantanu Narayen has led the company through a period of significant growth and innovation, and has overseen the development of new products and services.


                  </p>
                </li>
                <li>
                  <p>
                    <h5>Visionary: </h5>Shantanu Narayen is widely regarded as a visionary leader, who has focused on the power of technology to transform industries and societies.


                  </p>
                </li>
                <li>
                  <p>
                    <h5>Honours and awards: </h5>Shantanu Narayen has received several honours and awards for his contributions to business and technology, including being named one of Time's 100 most influential people.


                  </p>
                </li>
              </ul>
            </p>

          </div>
        </div> */}

      </div>

    </div>
  )
}

export default Proud;
