import React from "react";

import "./tfss.scss";

function Tfss002(){


    return(
        <div className="cert">
            <img src={require("../images/tfss002.png")} alt="" />
            
        </div>
    )
}

export default Tfss002;