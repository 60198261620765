import React from "react";
import "./Home.scss";
import thefaujilogo from "../images/TheFaujiLogo.png";
import saltlogo from "../images/SaltLogo.png";

function Home() {
    return (
        <div className="home">
            <img className="logo" src={thefaujilogo} alt="logo" />
            <h5>A Company Managed by Defence Veterans.</h5>
            <hr />
            <h3>The Product</h3>
            <img className="logo2" src={saltlogo} alt="Product"  />
            <hr />
            <div id="story">
                <h3>The Story</h3>
                <p>The project supports inclusiveness
                    of various classes from
                    manufacturing to the consumers
                    with right wages to labour,
                    employment generation in the
                    packaging and distribution
                    segment, to adequate margins to
                    retailer and ultimately competitive
                    cost for high quality salt to the
                    consumer. The part - profits are
                    also diverted to the war-widow
                    funds of uniformed services
                    rendering the optimum essence by
                    the company validating its
                    inclusive stand of “MAIN HOON
                    JANTA, MAIN BHI FAUZ”.
                </p>
            </div>
        </div>
    )
}
export default Home;