import React from 'react';
import "./Constitution.scss";
import thefaujilogo from "../images/TheFaujiLogo.png";
import pic1 from "../images/istockphoto-1296965095-612x612.jpg";
import pic2 from "../images/istockphoto-1203781656-612x612.jpg";
import pic3 from "../images/istockphoto-908134718-612x612.jpg";
import pic4 from "../images/istockphoto-1300980488-612x612.jpg";
import pic5 from "../images/istockphoto-1056364548-612x612.jpg";


function Constitution() {


  return (
    <div className='constitution-body'>
                  <img className="logo" src={thefaujilogo} alt="logo" />

      <div>
        <h2 className='constitution-title'>The Indian Constitution and its history</h2>
      </div>
      <div>
        <div className='events row'>
          <div className='col-lg-4'>
            <img  src="https://live.staticflickr.com/129/318273114_e5c6a887fd_n.jpg" alt="" />
          </div>
          <div className='col-lg-8'>
          <p className='event-para'>
          <h5>Background and History</h5>
            <ul>
              <li>
                <p>
                The Indian Constitution is the supreme law of India, adopted in 1949 and effective from 1950.

                </p>
              </li>
              <li>
                <p>
                It resulted from a process of constitutional reform and political negotiations after India's independence from British colonial rule in 1947.
                 </p>
              </li>
              <li>
                <p>
                The Constituent Assembly, consisting of representatives from various political parties, communities, and regions of India, drafted the Constitution.

                </p>
              </li>
              <li>
                <p>
                The Constitution drew inspiration from various sources, including other countries' constitutions and the Indian freedom struggle.
                </p>
              </li>
            </ul>
          </p>
            
          </div>
        </div>

        <div className='events row'>
        <div className='col-lg-4'>
            <img className='image' src="https://img.freepik.com/free-vector/hand-drawn-ambedkar-jayanti-illustration_23-2148904261.jpg?size=626&ext=jpg" alt="" />

          </div>
          <div className='col-lg-8'>
          <p className='event-para'>
          <h5>Key Features and Provisions</h5>
            <ul>
              <li>
                <p>
                The Constitution is divided into 22 parts and 395 articles, and it establishes a federal system of government with a strong central government and state governments.

                </p>
              </li>
              <li>
                <p>
                It also sets out a complex system of checks and balances among the three branches of government: the executive, legislative, and judicial branches.
                </p>
              </li>
              <li>
                <p>
                Some of the key provisions of the Constitution include the right to equality, freedom of speech and expression, and the right to education.

                </p>
              </li>
              <li>
                <p>
                It also guarantees the right to a fair trial, the right to vote, and the right to practice any religion.
                </p>
              </li>
              <li>
                <p>
                The Constitution also sets out the powers and responsibilities of the President, Prime Minister, and other government officials, as well as the role of the Supreme Court and other courts in interpreting and enforcing the law.
                </p>
              </li>
              <li>
                <p>
                The Constitution can be amended through a complex process that requires the approval of both houses of Parliament and at least half of the state legislatures.
                </p>
              </li>
            </ul>
          </p>
            
          </div>
          

        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src="https://img.freepik.com/free-photo/still-life-with-scales-justice_23-2149776027.jpg?size=626&ext=jpg" alt="" />

          </div>
          <div className='col-lg-8'>
          <p className='event-para'>
          <h5>Significance and Legacy</h5>
            <ul>
              <li>
                <p>
                The Indian Constitution is a significant achievement in India's democratic and constitutional history.

                </p>
              </li>
              <li>
                <p>
                It has played a crucial role in shaping India's political, social, and economic development over the past 70 years.
                </p>
              </li>
              <li>
                <p>
                The Constitution reflects the diversity and pluralism of Indian society and promotes principles of secularism, democracy, and equality.

                </p>
              </li>
              <li>
                <p>
                The Constitution has been amended several times, reflecting changing social and political conditions.



</p>
              </li>
            </ul>
          </p>
            
          </div>

        </div>

      </div>
      <hr />

      <div>
        <h2>Our Fundamental Rights</h2>
      </div>
      <div>

        <div>
          <h6 className='fundamental-para'>
            The Constitution of India guarantees certain fundamental rights to all its citizens. These rights are essential to the
            development and dignity of individuals and are considered to be the foundation of a free and democratic society.
          </h6>
        </div>

        <div className='events row'>
          <div className='col-lg-4'>
            <img className='image' src="https://img.freepik.com/free-photo/multiracial-hands-holding-cardboard-with-equality_23-2148734059.jpg?size=626&ext=jpg" alt="" />

          </div><div className='col-lg-8'>

            <div>
              <ul>
                <li>
                  <p className='event-para '>
                    <h5>Right to Equality:</h5> This includes equality before the law, prohibition of discrimination on grounds of religion, race, caste, sex
                    or place of birth, and equality of opportunity in matters of public employment.
                  </p>
                </li>
                <p>

                </p>
                <li>
                  <p className='event-para '>
                    <h5>Right to Freedom:</h5> Every citizen has the freedom of speech and expression, assembly, association, movement, and profession.
                  </p>
                </li>
                <p>

                </p>
                <li>
                  <p className='event-para '>
                    <h5>Right against Exploitation:</h5> No citizen can be forced to work in hazardous conditions or be trafficked, and children cannot be employed in hazardous jobs.
                  </p>
                </li>
              </ul>





            </div>
          </div>


        </div>
        <div className='events row'>
        <div className='col-lg-4'>
            <img className='image' src="https://img.freepik.com/free-photo/graduation-cap-earth-globe_23-2148721283.jpg?size=626&ext=jpg" alt="" />

          </div>
          <div className='col-lg-8'>
            <div>
              <ul>
                <li>
                  <p className='event-para '>
                    <h5>Right to Freedom of Religion:</h5> This includes the freedom to practice, profess, and propagate any religion of one's choice, and the freedom
                    to manage religious affairs.
                  </p>
                </li>
                <p>

                </p>
                <li>
                  <p className='event-para '>
                    <h5>Cultural and Educational Rights:</h5> This guarantees the right to protect and preserve the language, script, and culture of minority groups,
                    and the right to establish and administer educational institutions of their choice.
                  </p>
                </li>
                <p>

                </p>
                <li>
                  <p className='event-para '>
                    <h5>Right to Constitutional Remedies:</h5> This ensures that citizens have the right to approach the courts for the enforcement of their fundamental
                    rights.
                  </p>
                </li>
              </ul>





            </div>
          
        </div>
          


        </div>
      </div>
      {/* <div>
            <h2>Difficulties Faced While Creating Indian Constitution</h2>
        </div>
        <div>
            <p>
            Diverse opinions and perspectives: The Constituent Assembly was made up of members from various backgrounds, regions, and political 
            affiliations. This led to a diversity of opinions and perspectives on how the Constitution should be drafted, and it was often 
            challenging to reach a consensus on important issues.
            </p>
            <p>
            Communal tensions: India was going through a period of communal tension during the drafting of the Constitution, with violence and 
            conflicts between different religious and ethnic groups. This made it difficult to ensure that the Constitution represented the 
            interests and concerns of all communities and religions.
            </p>
            <p>
            Language issues: India is a multilingual country, and it was a challenge to ensure that the Constitution was accessible and 
            understandable to people in all parts of the country. The Constituent Assembly had to decide which language should be used in the 
            Constitution and how to accommodate regional languages.
            </p>
            <p>
            Socio-economic issues: India was grappling with significant socio-economic challenges at the time of the Constitution's drafting, 
            including poverty, illiteracy, and inequality. It was a challenge to ensure that the Constitution addressed these issues and provided 
            a framework for social and economic development.
            </p>
            <p>
            Time constraints: The Constituent Assembly had a tight deadline to complete the drafting of the Constitution. There was pressure to 
            complete the process quickly, which made it difficult to give sufficient time and attention to all the complex issues involved.
            </p>
        </div> */}
    </div>
  )
}

export default Constitution;
