import React from 'react';
import './QR.scss';
import chakra from "../images/chakra.png"
import saffron from "../images/saffron.png"
import white from "../images/white.png"
import green from "../images/green.png"
import logo from "../images/TheFaujiLogo.png"
import pic1 from "../images/istockphoto-1296965095-612x612.jpg";
import FirstWar from "../images/03-1857-rebellion.jpg"


function QR() {
    return (

        <div className='title'>
            <img className='title-img' src={logo} alt="THE FAUJI" />
            <h2 className='heading'>KNOW YOUR TRICOLOUR</h2>
            <p>Each colour and symbol in the Indian national flag contains a unique message that is as relevant to our professional lives as our personal lives.</p>

            <div className='elements row m-0'>
                <div className='flag-color col-lg-4 col-md-4'>
                    <img className='flag-img' src={saffron} alt='color1' />
                    <h2>SAFFRON</h2>
                    <br />
                    <p>Symbolises mental strength which is crucial for career success.</p>
                </div>

                <div className='flag-color col-lg-4 col-md-4'>
                    <img className='flag-img' src={white} alt='color2' />
                    <h2>WHITE</h2>
                    <br />
                    <p>Represents peace and truth. The pursuit of peace and habit of truth-telling empowers us with the clarity and honesty we need to accomplish our professional goals.</p>
                </div>

                <div className='flag-color col-lg-4 col-md-4'>
                    <img className='flag-img' src={green} alt='color3' />
                    <h2>GREEN</h2>
                    <br />
                    <p>Stands for creative fertility and growth in the workplace.</p>
                </div>
            </div>

            <div className='flag-color'>
                <div className='chakra-all row m-0'>
                    <div className='chakra-pic col-lg-5 col-md-5'>
                        <img className='flag-img' src={chakra} alt='color4' />
                        <h2>DHARMA CHAKRA</h2>
                    </div>

                    <div className='col-lg-7 col-md-5'>
                        <div className='chakra-txt'>
                            <p>Each of the 24 separate spokes connect to a single wheel representing a unique virtue.</p>
                        </div>
                        <div className='bullets row'>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-6'>
                                <ul className='sub-bullets'>
                                    <li>Courage</li>
                                    <li>Love</li>
                                    <li>Patience</li>
                                    <li>Peace</li>
                                    <li>Justice</li>
                                    <li>Magnanimity</li>
                                    <li>Goodness</li>
                                    <li>Righteousness</li>

                                </ul>

                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-6'>

                                <ul className='sub-bullets'>
                                    <li>Faith</li>
                                    <li>Grace</li>
                                    <li>Gentleness</li>
                                    <li>Selflessness</li>
                                    <li>Self Control</li>
                                    <li>Sympathy</li>
                                    <li>Mercy</li>
                                    <li>Truthfulness</li>

                                </ul>

                            </div>
                            <div className='col-lg-4 col-md-4'>

                                <div className='row'>
                                    <div className='col-lg-12 col-md-12 col-6'>
                                        <ul className='sub-bullets'>
                                            <li>Humility</li>
                                            <li>Empathy</li>
                                            <li>Self Sacrifice</li>
                                            <li>Wisdom</li>



                                        </ul>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-6'>
                                        <ul>
                                            <li>Morals</li>
                                            <li>Spiritual Knowledge</li>
                                            <li>Fear of God</li>
                                            <li>Hope or Belief</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


            </div>

            <hr />
            <div className='constitution-title'>
                <h2>The Indian Constitution and its history</h2>
            </div>
            <div>
                <div className='events row'>
                    <div className='col-lg-4'>
                        <img className='image' src="https://live.staticflickr.com/129/318273114_e5c6a887fd_n.jpg" alt="" />
                    </div>
                    <div className='col-lg-8'>
                        <p className='event-para'>
                            <h5>Background and History</h5>
                            <ul>
                                <li>
                                    <p>
                                        The Indian Constitution is the supreme law of India, adopted in 1949 and effective from 1950.

                                    </p>
                                </li>
                                <li>
                                    <p>
                                        It resulted from a process of constitutional reform and political negotiations after India's independence from British colonial rule in 1947.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The Constituent Assembly, consisting of representatives from various political parties, communities, and regions of India, drafted the Constitution.

                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The Constitution drew inspiration from various sources, including other countries' constitutions and the Indian freedom struggle.... <a className='blogs' href="/the-constitution">Read more</a>
                                    </p>
                                </li>
                            </ul>
                        </p>

                    </div>
                </div>
            </div>

            <hr />
            <div>
                <h2>History of India's Independence</h2>
            </div>
            <div>

                <div className='events row'>
                    <div className='col-lg-4'>
                        <img className='image' src={FirstWar} alt="First War of Indian Independence" />

                    </div>
                    <div className='col-lg-8'>
                        <p className='event-para'>
                            <h5>First War of Indian Independence (1857):</h5>
                            <ul>
                                <li>
                                    <p>
                                        The First War of Indian Independence was an uprising against British colonial rule in India, caused by economic
                                        exploitation, religious and cultural tensions, and a desire for political freedom.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        It began in May 1857 when Indian soldiers known as sepoys revolted in Meerut and quickly spread across India,
                                        involving various groups for about two years.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The rebellion exposed the exploitative nature of British colonial rule and fueled the Indian freedom struggle.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Its impact on India's struggle for independence was significant, leading to the end of British East India Company's
                                        rule and India's independence in 1947.... <a className='blogs' href="/the-independence">Read more</a>
                                    </p>
                                </li>
                            </ul>
                        </p>
                    </div>

                </div>
            </div>
            <hr />
            <div>
                <h2 className='proud-title'>Proud Facts About India</h2>
            </div>
            <div>


                <div className='events row'>
                    <div className='col-lg-4'>
                        <img className='image' src="https://live.staticflickr.com/34/67394736_37a12a3ed5_n.jpg" alt="" />

                    </div>
                    <div className='col-lg-8'>
                        <p className='event-para'>
                            <h4>Indian Culture</h4>
                            <ul>
                                <li>
                                    <p>
                                        India is home to one of the world's oldest civilizations, the Indus Valley Civilization, which flourished over 5,000 years ago.

                                    </p>
                                </li>
                                <li>
                                    <p>
                                        India has a rich tradition of literature, with ancient texts like the Vedas, the Mahabharata, and the Ramayana, as well as more recent works by writers like Rabindranath Tagore and Vikram Seth.

                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Indian classical music and dance are world-renowned, with various styles and forms that have been developed over centuries.

                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Indian cuisine is diverse and flavorful, with regional specialties that range from spicy curries to sweet desserts.


                                    </p>
                                </li>
                                <li>
                                    <p>
                                        India is known for its traditional handicrafts, including intricate embroidery, pottery.... <a className='blogs' href="the-proud">Read More</a>
                                    </p>
                                </li>
                            </ul>
                        </p>

                    </div>


                </div>

            </div>
            <hr />
            <div>
                <h2 className='proud-title'>Proud Facts About Indian Armed Forces</h2>
            </div>
            <div>

                <div className='events row'>

                    <div className='col-lg-4'>
                        <img className='image' src="https://images.pexels.com/photos/13316041/pexels-photo-13316041.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                    </div>
                    <div className='col-lg-8'>
                        <p className='event-para'>
                            <h4>Indian Army</h4>
                            <ul>
                                <li>

                                    <p><h5>World's 2nd largest army: </h5>With over 1.4 million active troops and another million reserve troops, the Indian Army is the second largest army in the world.
                                    </p>
                                </li>
                                <li>

                                    <p>

                                        <h5>Modern capabilities: </h5>Today, the Indian Army is a modern, well-equipped force with advanced weaponry and technology, including tanks, aircraft, and drones.

                                    </p>
                                </li>
                                <li>

                                    <p>


                                        <h5>War history: </h5>The Indian Army has a proud history of defending the nation in times of war, including major conflicts such as the 1965 war with Pakistan and the 1971 war that led to the liberation of Bangladesh.

                                    </p>
                                </li>
                                <li>

                                    <p>
                                        <h5>Surgical Strike: </h5>

                                        In 2016, Indian Army conducted a surgical strike against terrorist bases in Pakistan-occupied Kashmir. The operation was a success and sent a strong message to the terrorists and their sponsors.                </p>
                                </li>
                                <li>

                                    <p>

                                        <h5>Courage and sacrifice: </h5>Indian soldiers are known for their bravery and sacrifice, with many soldiers having received the highest awards for gallantry, such as the Param Vir Chakra and the Ashoka Chakra.... <a className='blogs' href="/the-armed-forces">Read More</a>

                                    </p>
                                </li>
                            </ul>
                        </p>

                    </div>
                </div>
            </div>
        </div>

    )
}
export default QR;