import React from "react";
import { Link } from 'react-router-dom';
import "./Header.scss";
import TheFaujiLogo from '../images/TheFaujiLogo.png';

function Header() {
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary navbar-light ">
            <div className="container d-flex py-3 mb-4  border-bottom">
                <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                    <span className="fs-4"><img src={TheFaujiLogo} height='24rem' alt="logo" /></span>
                </Link>
                <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                    <ul className="flex-column flex-sm-row nav nav-pills">
                        <li className="nav-item selected"><Link to="/" className="nav-link active" aria-current="page">Home</Link></li>
                        <li className="nav-item"><Link to="/the-patriotism" className="nav-link">The Patriotism</Link></li>
                        <li class="nav-item dropdown">
                            <Link class="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Products
                            </Link>
                            <ul class="dropdown-menu">
                                <li><Link class="dropdown-item" to="/">The Salt</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item"><Link to="/contact-us" className="nav-link">Contact us</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    )

}
export default Header;