import React from 'react';
import "./Footer.scss";

function Footer() {
    return (
        <footer id="footer" >
            <a href="https://www.facebook.com/profile.php?id=100090533907888"
                className="footer-icon fa-brands fa-facebook"
                target="_blank" 
                rel="noopener noreferrer">
            </a>
            <a href="https://www.instagram.com/thefaujisaltofficial/"
                className="footer-icon fa-brands fa-instagram"
                target="_blank" 
                rel="noopener noreferrer">
            </a>
            <a href="https://www.linkedin.com/in/thefauji"
                className="footer-icon fa-brands fa-linkedin-in"
                target="_blank" 
                rel="noopener noreferrer">
            </a>
            <a href="https://mail.google.com/mail/?to=thefaujisalt@gmail.com&bcc=admin@example.com&subject=Hey#compose"
                className="footer-icon fa-solid fa-envelope"
                target="_blank" 
                rel="noopener noreferrer">

            </a>
            <a href="https://www.youtube.com/channel/UCWjjcBz99xMrRiyf1IS58VA"
                className="footer-icon fa-brands fa-youtube"
                target="_blank" 
                rel="noopener noreferrer">
                    {/* <i class="fa-brands fa-youtube"></i> */}

            </a>
            <p>© Copyright THE FAUJI</p>

        </footer>
    )
}
export default Footer;